type ProcessStatus = {
    font: string;
    label: string;
    bgcolor: string;
};

export const processStatus: Record<string, ProcessStatus> = {
    in_progress: {
        font: "#FFA726",
        label: "En progreso",
        bgcolor: "#FFF3E0",
    },
    completed: {
        font: "#66BB6A",
        label: "Completado",
        bgcolor: "#E8F5E9",
    },
    expired: {
        font: "#EF5350",
        label: "Expirado",
        bgcolor: "#FFEBEE",
    },
};

export const person_types: Record<string, string> = {
    natural: "Natural",
    juridica: "Jurídica",
};

export const filterNames: Record<
    string,
    { formatFn: (value: unknown) => string }
> = {
    status: { formatFn: (value) => processStatus[value as string].label },
    has_mobility_service: {
        formatFn: (value) => (value ? "Movilidad eléctrica" : ""),
    },
    has_solar_service: {
        formatFn: (value) => (value ? "Monitoreo solar" : ""),
    },
    person_type: { formatFn: (value) => person_types[value as string] },
};
